export enum ApplicationFeature {
    ThemeTest = 'ThemeTest',
    ExperimentalPluginFeatures = 'ExperimentalPluginFeatures',
    WebTrafficMetrics = 'WebTrafficMetrics',
    TeamPerformance = 'TeamPerformance',
    GBPMetrics = 'GBPMetrics',
    JobImporter = 'JobImporter',
    MediaEditor = 'MediaEditor',
    CustomLabels = 'CustomLabels',
    PBIDashboard = 'PBIDashboard',
    ArchiveJobs = 'ArchiveJobs',
    JobsByAreaDashboard = 'JobsByAreaDashboard',
}

// only exported for the tests
export const GLOBAL_ENABLED_FEATURES: Array<ApplicationFeature> = [];

export class ApplicationFeatureService {
    readonly localStorage: Storage;
    readonly isDevelopment: boolean;
    readonly prefix = 'RWL:FEATURES:';

    constructor(storage: Storage, isDevelopment: boolean) {
        this.localStorage = storage;
        this.isDevelopment = isDevelopment;
    }

    private getKey(feature: ApplicationFeature): string {
        return this.prefix + feature.toString();
    }

    private disableInLocalStorage(feature: ApplicationFeature): void {
        this.localStorage.setItem(this.getKey(feature), 'false');
    }

    private enableInLocalStorage(feature: ApplicationFeature): void {
        this.localStorage.setItem(this.getKey(feature), 'true');
    }

    private clearInLocalStorage(feature: ApplicationFeature): void {
        this.localStorage.removeItem(this.getKey(feature));
    }

    private getFromLocalStorage(feature: ApplicationFeature): string | null {
        return this.localStorage.getItem(this.getKey(feature));
    }

    status(): string {
        return Object.values(ApplicationFeature)
            .map((key) => `${key}: ${this.isEnabled(key)}`)
            .join('  \n');
    }

    isEnabled(feature: ApplicationFeature): boolean {
        const value = this.getFromLocalStorage(feature);
        if (value !== null) {
            return value === 'true';
        }

        // check to see if it was enabled for everyone
        if (GLOBAL_ENABLED_FEATURES.includes(feature)) {
            return true;
        }

        return this.isDevelopment;
    }

    enableAll(): void {
        for (const feature in ApplicationFeature) {
            this.enable(feature as ApplicationFeature);
        }
    }

    disableAll(): void {
        for (const feature in ApplicationFeature) {
            this.disable(feature as ApplicationFeature);
        }
    }

    clearAll(): void {
        for (const feature in ApplicationFeature) {
            this.clear(feature as ApplicationFeature);
        }
    }

    enable(feature: ApplicationFeature): void {
        this.enableInLocalStorage(feature);
    }

    disable(feature: ApplicationFeature): void {
        this.disableInLocalStorage(feature);
    }

    clear(feature: ApplicationFeature): void {
        this.clearInLocalStorage(feature);
    }
}
